/**
 * Удаление лоадера из dom дерева после загрузки страницы
 * Скрипт отрабатывает в любом случае, даже если есть ошибки в JS
 * Загрузка происходит при первом заходе
 */

window.onload = function () {
    var date = new Date();
    var days = 365;
    let loader = document.querySelector('.loader');
    date.setTime(date.getTime() + days * (24 * 60 * 60 * 1000));

    setTimeout(function(){
        if ($('.loader').length > 0 ){
            loader.parentNode.removeChild(loader);
        }
        $.cookie('is_preloader_shown', 'yes', {expires: date, path: '/'});
    },2000);

    if   ($.cookie('is_preloader_shown') === 'yes') {
        loader.parentNode.removeChild(loader);
    }
}

$(document).ready(function() {

    /**
     * Глобальные переменные
     */
    var WIN = $(window),
        DOC = $(document),
        HTML = $('html');
    /**
     * Определяем браузеры Internet Explorer 11 и Edge
     */
    let ua = window.navigator.userAgent,
        edge_ua = ua.indexOf('Edge/'),
        ie_ua = ua.indexOf('Trident/7.0'),
        edge = 'edge',
        ie = 'ie';
    if (edge_ua > 0) {
        HTML.addClass(edge);
    }
    if (ie_ua + 1) {
        HTML.addClass(ie);
    }

    /**
     * Добавляем стили и скрипты для браузера Internet Explorer 11
     */
    if (HTML.hasClass(ie)) {
        HTML.append('<script src="../../js/' + ie + '.js"></script>');
        HTML.append('<link href="../../css/' + ie + '.css" rel="stylesheet">');
    }
    /**
     * Главный слайдер на главной странице
     */
    var mainSlider = new Swiper('[data-slider-main]', {
        speed: 1000,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        initialSlide: 0,
        centeredSlides: true,
        loop: true,
        preloadImages: false,
        keyboard: {
            enabled: true,
        },
        lazy: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: '.slider__button-next',
            prevEl: '.slider__button-prev',
        }
    });

    /**
     * Слайдер fit-menu активируется нв мобильных разрешениях
     */

    // попытка №4
    /*   if($('[data-fitmenu-slider]').length){
           var settings = {
                   loop: true,
                   centeredSlides: true,
                   slidesPerGroup: 1,
                   spaceBetween: 20,
                   slidesPerView: 'auto'
               },
               menuSlider = new Swiper('[data-fitmenu-slider]', settings);

           if($(window).width() <= 980){
               menuSlider.destroy(false, true);
           }

           $(window).on('resize', function() {
               if(menuSlider != undefined){
                   if($(window).width() <= 980){

                       menuSlider.destroy(true, true);
                       menuSlider = undefined;
                       $('[data-fitmenu-slider] .swiper-wrapper').removeAttr('style');
                       $('[data-fitmenu-slider] .swiper-slide').removeAttr('style');
                   }
               }
               else if($(window).width() > 981){
                   console.log('init');
                   favSlider = new Swiper ('[data-fitmenu-slider]', settings);
               }

           });
       }*/




    // (function() {
    //     'use strict';
    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia( '(min-width:980px)' );
    // keep track of swiper instances to destroy later
    let mySwiper;
    const breakpointChecker = function() {
        // if larger viewport and multi-row layout needed
        if ( breakpoint.matches === true ) {
            // clean up old instances and inline styles when available
            if ( mySwiper !== undefined )
                $('[data-fitmenu-slider]').each(function(){
                    this.swiper.destroy(true, true); // <-- 'this' not 'this[0]' worked for me
                    jQuery('[data-fitmenu-slider] .swiper-wrapper').removeAttr('style');
                    jQuery('[data-fitmenu-slider] .swiper-slide').removeAttr('style');
                });

            // mySwiper.destroy( true, true );

            // or/and do nothing
            return;
            // else if a small viewport and single column layout needed
        } else if ( breakpoint.matches === false ) {
            // fire small viewport version of swiper
            return enableSwiper();
        }
    };
    const enableSwiper = function() {
        mySwiper = new Swiper ($('[data-fitmenu-slider]:visible'), {
            loop: true,
            freeMode: false,
            centeredSlides: true,
            slidesPerGroup: 1,
            spaceBetween: 20,
            slidesPerView: 'auto',
            on: {
                init: function () {
                    /** Инициализация модалок **/
                    $('[data-modal]').magnificPopup({
                        type: 'ajax',
                        removalDelay: 300,
                        mainClass: 'mfp-zoom-in',
                        callbacks: {
                            parseAjax: function(mfpResponse) {
                                mfpResponse.data = "<div class='modals-wrapping'>" + mfpResponse.data + "</div>";
                            },
                            ajaxContentAdded: function() {
                                this.content;
                            },
                            beforeOpen: function() {
                                $('body').addClass('blur');
                            },
                            beforeClose: function() {
                                $('body').removeClass('blur');
                            }
                        }
                    });
                },
            },
        });
    };
    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker);

    // kickstart
    breakpointChecker();
    // })(); /* IIFE end */






    /**
     * End Слайдер fit-menu активируется нв мобильных разрешениях
     */



    /** Слайдер отзывов на главной **/
    var reviewsSlider = new Swiper('[data-reviews-slider] .swiper-container', {
        speed: 500,
        loop: true,
        slidesPerView: 3,
        slidesPerGroup: 1,
        spaceBetween: 40,
        centeredSlides: true,
        autoplay: true,
        navigation: {
            nextEl: '.slider-section__button-next',
            prevEl: '.slider-section__button-prev',
        },
        breakpoints: {
            480: {
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 10
            },
            980: {
                slidesPerView: 'auto',
                centeredSlides: true
            }
        }
    });
    /** End слайдер отзывов на главной **/


    /** Слайдер постов блога на главной **/
    var blogSlider = new Swiper('[data-blog-slider] .swiper-container', {
        speed: 500,
        loop: true,
        slidesPerView: 3,
        // slidesPerGroup: 3,
        spaceBetween: 40,
        navigation: {
            nextEl: '.slider-section__button-next',
            prevEl: '.slider-section__button-prev',
        },
        breakpoints: {
            480: {
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 10
            },
            980: {
                slidesPerView: 'auto',
                centeredSlides: true
            }
        }
    });
    /** End слайдер постов блога на главной **/


    /** Слайдер постов instagram на главной **/
    var instagramSlider = new Swiper('[data-instagram-slider] .swiper-container', {
        speed: 500,
        effect: 'coverflow',
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 50,
            modifier: 1,
            slideShadows : false,
        },
        loop: true,
        slidesPerView: 3,
        spaceBetween: 75,
        navigation: {
            nextEl: '.slider-section__button-next',
            prevEl: '.slider-section__button-prev',
        },
        pagination: {
            el: '[data-instagram-slider] .swiper-pagination',
            clickable: true
        },
        breakpoints: {

            980: {
                effect: 'slide',
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 10
            },
            1024: {
                slidesPerView: 2
            }
        }
    });

    /** End слайдер постов instagram на главной **/

    /** Слайдер постов youtube на главной **/
    var youtubeSlider = new Swiper('[data-youtube-slider] .swiper-container', {
        speed: 500,
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        // slidesPerGroup: 3,
        spaceBetween: 0,
        navigation: {
            nextEl: '.slider-youtube__button-next',
            prevEl: '.slider-youtube__button-prev',
        },
        breakpoints: {
            640: {
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 10
            }

        }

    });

    /** End слайдер постов youtube на главной **/




    /** Tabs + cookies / menu type **/
    function menuSwitcher(e) {
        const menuMeat = $('[data-menu-meat]');
        const menuMeatContent = $('[data-menu-meat-content]');
        const menuVegan = $('[data-menu-vegan]');
        const menuVeganContent = $('[data-menu-vegan-content]');
        menuMeat.on('click', function () {
            menuVegan.removeClass('active');
            menuVeganContent.removeClass('active');
            menuMeat.addClass('active');
            menuMeatContent.addClass('active');
            $.cookie('menuSwitcher', 'menu-meat', {path: '/'});

        });
        menuVegan.on('click', function () {
            menuMeat.removeClass('active');
            menuMeatContent.removeClass('active');
            menuVegan.addClass('active');
            menuVeganContent.addClass('active');
            $.cookie('menuSwitcher', 'menu-vegan', {path: '/'});

        });
    }
    menuSwitcher();
    //-- End Tabs menu type












    // FAQ tabs
    $('ul.faq-tabs').on('click', 'li:not(.active)', function() {
        $(this).addClass('active').siblings().removeClass('active').closest('section.section--faq').find('div.faq-tabs__content').removeClass('active').eq($(this).index()).addClass('active');
    });
    // end FAQ tabs


    // обрезка превью новости (добавить инициализацию по дата атрибуту)
    var blogTitle = $('.post__title a');
    var blogContent = $('.post__description');
    blogTitle.each(function() {
        var size = 60,
            title = $(this).text();
        if (title.length > size) {
            $(this).text(title.slice(0, size) + ' ...');
        }
    });
    blogContent.each(function() {
        var size = 140,
            blogText = $(this).text();
        if (blogText.length > size) {
            $(this).text(blogText.slice(0, size) + ' ...');
        }
    });
    //-- End обрезка превью новости

    /** Phone input mask **/
    $(function($){
        $("input[type='tel']").mask('+(999) 999-999-999?999', {autoclear: false,})
    });


    /** Submenu **/
    WIN.scroll(function () {
        if ($(this).scrollTop() > 2500) {
            $('.sub-header').addClass("visible");
        } else {
            $('.sub-header').removeClass("visible");
        }
    });
    /** End Submenu **/

    //-- Реализация адаптивности iframe в статьях
    $('.article__content iframe').each(function() {
        $(this).wrap('<div class="videowrapper">');
    });
    //-- end

    //-- Реализация мобильного меню в модалках
    function mobileMenu() {
        let buttons = $('[data-mobile-menu]');
        buttons.on('click', function(e) {
            e.preventDefault();
            $.magnificPopup.close();
            let that = $(e.target);
            let href = e.target;
            if (!that.hasClass('active')) {
                buttons.removeClass('active');
                that.addClass('active');
                $.magnificPopup.open({
                    mainClass: 'mobile-wrapper',
                    modal: true,
                    showCloseBtn: false,
                    items: {
                        src: href
                    },
                    type: 'ajax'
                }, 0);
            } else {
                that.removeClass('active');
            }

        })
    }
    mobileMenu();
    WIN.on('resize', function() {
        $('[data-mobile-menu].active').each(function() {
            $('[data-mobile-menu].active').removeClass('active');
            $.magnificPopup.close();
        });
    });
    //-- End реализации мобильного меню в модальном окне// }

    /** Инициализация модалок **/
    $('[data-modal]').magnificPopup({
        type: 'ajax',
        removalDelay: 300,
        mainClass: 'mfp-zoom-in',
        callbacks: {
            parseAjax: function(mfpResponse) {
                mfpResponse.data = "<div class='modals-wrapping'>" + mfpResponse.data + "</div>";
            },
            ajaxContentAdded: function() {
                this.content;
            },
            beforeOpen: function() {
                $('body').addClass('blur');
            },
            beforeClose: function() {
                $('body').removeClass('blur');
            }
        }
    });

    //-- End Инициализация модалок
    /** Инициализация модалок inline **/
    $(document).on('ajaxSuccess', function() {

        $('[data-modal-inline]').magnificPopup({
            type: 'inline',
            midClick: true,
            removalDelay: 300,
            mainClass: 'mfp-zoom-in',
            callbacks: {
                parseAjax: function(mfpResponse) {
                    mfpResponse.data = "<div class='mfp-content'>" + "<div class='modals-wrapping'>" + mfpResponse.data + "</div>" +  "</div>";
                },
                ajaxContentAdded: function() {
                    this.content;
                },
                beforeOpen: function() {
                    $('body').addClass('blur');
                },
                beforeClose: function() {
                    $('body').removeClass('blur');
                    $('.last-step').fadeOut();
                }
            }
        });

    });
    $('[data-modal-inline]').magnificPopup({
        type: 'inline',
        midClick: true
    });

    /** End Инициализация модалок inline **/




    // /** Form extra fields toggler **/
    //
    // $(function () {
    //     $(".order__business").fadeOut();
    //     $("#order__business").click(function () {
    //         if ($(this).is(":checked")) {
    //             $(".order__business").fadeIn();
    //         } else {
    //             $(".order__business").fadeOut();
    //         }
    //     });
    // });
    //
    // $(function () {
    //     $(".adress_alternative").fadeOut();
    //     $("#adress_alternative").click(function () {
    //         if ($(this).is(":checked")) {
    //             $(".adress_alternative").fadeIn();
    //         } else {
    //             $(".adress_alternative").fadeOut();
    //         }
    //     });
    // });
    // /** END Form extra fields toggler **/

    /**
     * Событие клика на элементе faq. Показывает\скрывает ответ на вопрос
     */
    DOC.on('click', '[data-faq-question]', function() {
        var item = $(this).parents('.faq__item');
        item.find('.faq__answer').slideToggle();
        item.toggleClass('faq--open');
    });

    /**
     * Иниализация отложенной подгрузки изображений
     */
    function lazyLoad() {
        $('[data-lazy] img[data-src]').Lazy({
            scrollDirection: 'vertical',
            effect: 'fadeIn',
            delay: 10,
            visibleOnly: true,
            onError: function(element) {
                element.attr("src","../img/logos/logo.svg").addClass('no-photo');
            }
        });
    }

    lazyLoad();


    /** Days tabs для меню **/
    $('.weekly-nav__days-list').on('click', 'li:not(.active)', function() {
        $(this).addClass('active').siblings().removeClass('active').closest('.fit-menu__wrapper').find('div.fit-menu-day__wrapper').removeClass('active').eq($(this).index()).addClass('active');
        lazyLoad();


        breakpointChecker();

    });
    /** End Days tabs для меню **/

    /*** anchors + clicks for page navigation ***/

    // $('.fitness_super').click()(function(){
    //     $("#super_fit").click();
    // });



    /*** End anchors + clicks for page navigation***/



    /**
     * Событие смены видимости пароля в инпуте
     */
    var passwordToggler = ('[data-password-toggler]');

    DOC.on('click', passwordToggler, function() {
        var btn = $(this),
            input = btn.siblings('input');
        btn.toggleClass('icon-view');
        btn.toggleClass('icon-hide');
        if (input.attr('type') == 'text') {
            input.attr('type', 'password');
        } else {
            input.attr('type', 'text');
        }
    });

    /**
     * Submenu
     */

    $('.sub-header-menu__item.fitness_super').click(function(){
        $('.fit-menu-tabs').find('#super_fit').click();
    });
    $('.sub-header-menu__item.balance').click(function(){
        $('.fit-menu-tabs').find('#balance').click();
    });
    $('.sub-header-menu__item.fitness').click(function(){
        $('.fit-menu-tabs').find('#fit').click();
    });
    $('.sub-header-menu__item.power').click(function(){
        $('.fit-menu-tabs').find('#power').click();
    });

    /**
     * End Submenu
     */


    /**
     * Калькулятор коллорий
     */

    $('[data-click-menu]').click(function(){
        var kkal = $('#result-kkal').text();
        var $menu = $('.fit-menu-tabs');
        if(kkal<1100){
            $menu.find('#super_fit').trigger('click');
        }
        else if(kkal<1300){
            $menu.find('#fit').trigger('click');
        }
        else if(kkal<1600){
            $menu.find('#balance').trigger('click');
        }
        else if(kkal<2100){
            $menu.find('#daily').trigger('click');
        }
        else{
            $menu.find('#power').trigger('click');
        }
    });

    $('#age').val('');
    $('#height').val('');
    $('#weight').val('');

    $('.step-sex a').on('click', function(){
        $('.step-sex a').removeClass('active');
        $(this).addClass('active');
        $('.step-type__wrapper').fadeIn(700);
        calc();
    });

    $('.step-type a').on('click', function(){
        $('.step-type a').removeClass('active');
        $(this).addClass('active');
        $('.step-params__wrapper').fadeIn(700);
        calc();
    });

    $('.step-params').on('keyup', function(){
        if ($('#age').val() != '' && $('#height').val() != '' && $('#weight').val() != '') {
            $('.step-activity__wrapper').fadeIn(700);
            calc();
        } else {
            $('#result-kkal').html(0);
        }
    });

    $('.step-activity a').on('click', function(){
        $('.step-activity a').removeClass('active');
        $(this).addClass('active');
        $('.calculator-calories__result').fadeIn(700);
        calc();
    });

    function calc()
    {
        var sex = parseInt(jQuery('.step-sex a.active').data('val')) || 0,
            type = parseFloat(jQuery('.step-type a.active').data('val')) || 0,
            age = parseFloat(jQuery('#age').val()) || 0,
            height = parseFloat(jQuery('#height').val()) || 0,
            weight = parseFloat(jQuery('#weight').val()) || 0,
            active = parseFloat(jQuery('.step-activity a.active').data('val')) || 0,
            k = sex == 1 ? 5 : -161;
        if (weight == 0 || height == 0 || age == 0) {
            jQuery('#result-kkal').html(0);
            return;
        }
        var result = parseFloat((k + 9.99 * weight + 6.25 * height - 4.92 * age) * active);
        result = result + result * type;

        $('#result-kkal').html(Math.round(result));
    }

    $('[data-reset-result]').click(function() {
        $('.calculator-calories__result, .step-activity__wrapper, .step-params__wrapper,.step-type__wrapper').fadeOut();
        $('#calculator a').removeClass('active');
        $('#age').val('');
        $('#height').val('');
        $('#weight').val('');
    });


    /**
     * End Калькулятор коллорий
     */


    /**
     * Инициализация плагина обратного отсчета
     */
    $('[data-time]').each(function() {
        $(this).countdown($(this).attr('data-time'), function(event) {
            $(this).html(event.strftime('' +
                '<span>%M</span>' +
                '<span>:</span>' +
                '<span>%S</span>'
            ));
        });
    });
    /** реинит свайпера при смене дней недели - костыль!!!! нужно переделать **/

    function weeklyNavSwiper() {

        $('.weekly-nav__days-list').on('click', 'li:not(.active)', function() {
            breakpointChecker();
        });
        $('.weekly-nav__weeks').on('click', 'li:not(.active)', function() {
            breakpointChecker();
        });
    }



    /** Событие срабатывает в случае успешного выполнения ajax запроса **/
    DOC.on('ajaxSuccess', function() {
        lazyLoad();
        //-- Инициализация модалок
        $('[data-modal]').magnificPopup({
            type: 'ajax',
            removalDelay: 300,
            mainClass: 'mfp-zoom-in',
            callbacks: {
                parseAjax: function(mfpResponse) {
                    mfpResponse.data = "<div class='modals-wrapping'>" + mfpResponse.data + "</div>";
                },
                ajaxContentAdded: function() {
                    this.content;
                },
                beforeOpen: function() {
                    $('body').addClass('blur');
                },
                beforeClose: function() {
                    $('body').removeClass('blur');
                }
            }
        });
        //-- End Инициализация модалок
        /** Phone input mask **/
        $(function($){
            $("input[type='tel']").mask('+(999) 999-999-999?999', {autoclear: false,})
        });
        $(function() {
            $('.tabs').fwd_tabs();
        });

        breakpointChecker();

        weeklyNavSwiper();

        $(function () {
            $('.form--order-test-menu').parsley();
        });
    });

    /** Форма заказа fit-menu (step by step) **/
    // DOC.on('ajaxSuccess', function() {
    //     $(function () {
    //         var $sections = $('.form--order-step:not(.last-step)');
    //
    //         function navigateTo(index) {
    //             // Mark the current section with the class 'current'
    //             $sections
    //                 .removeClass('current')
    //                 .eq(index)
    //                 .addClass('current');
    //             // Show only the navigation buttons that make sense for the current section:
    //             $('.form-previous').toggle(index > 0);
    //             var atTheEnd = index >= $sections.length - 1;
    //             $('.form-navigation .next').toggle(!atTheEnd);
    //             $('.form-navigation [type=submit]').toggle(atTheEnd);
    //         }
    //
    //         function curIndex() {
    //             // Return the current index by looking at which section has the class 'current'
    //             return $sections.index($sections.filter('.current'));
    //         }
    //
    //         // Previous button is easy, just go back
    //         $('.form-previous').click(function() {
    //             navigateTo(curIndex() - 1);
    //         });
    //
    //         // Next button goes forward iff current block validates
    //         $('.form-navigation .next').click(function() {
    //             $('.form--order').parsley().whenValidate({
    //                 group: 'block-' + curIndex()
    //             }).done(function() {
    //                 navigateTo(curIndex() + 1);
    //             });
    //         });
    //
    //         // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
    //         $sections.each(function(index, section) {
    //             $(section).find(':input').attr('data-parsley-group', 'block-' + index);
    //         });
    //         navigateTo(0); // Start at the beginning
    //     });
    // });

    /** End - Форма заказа fit-menu (step by step) **/


    /** Accept Cookies **/
    setTimeout(function () {
        $('[data-cookie-messege]').addClass("show")
    }, 2000);

    var date = new Date();
    var days = 365;
    date.setTime(date.getTime() + days * (24 * 60 * 60 * 1000));

    $('[data-cookie-accept]').click(function(){
        $.cookie('new-cookies-accepted', 'yes', { expires: date, path: '/' });
        if ( $.cookie('new-cookies-accepted') === 'yes' ) {
            $('[data-cookie-messege]').fadeOut(300, function() {
                $(this).remove();
            });
            // $('[data-cookie-messege]').removeClass('show').remove();
        }

    });

    if ( $.cookie('new-cookies-accepted') === 'yes' ) {
        $('[data-cookie-messege]').remove();
    }
    /** End Cookies **/


    /**
     * Условие проверки входа через IE
     * И вывод соответствующего сообщения о устаревшем браузере
     **/
    if (!HTML.hasClass('ie')) {
        $('.ieOff').remove();
    }
    /** ------------------------------------------------------------------------ **/
    /**
     * Событие клика на элементе cookie. Показывает\скрывает ответ на вопрос
     */
    DOC.on('click', '[data-cookie-question]', function() {
        var item = $(this).parents('.cookie-list__item');
        item.find('.cookie__answer').slideToggle();
        item.toggleClass('open');
    });
    DOC.on('click', '[data-cookie-toggler]', function() {
        $('.cookie-list__wrapper').slideToggle();
    });





});
